exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prediction-js": () => import("./../../../src/pages/prediction.js" /* webpackChunkName: "component---src-pages-prediction-js" */),
  "component---src-pages-prediction-za-powerball-prediction-for-tuesday-22-october-2024-js": () => import("./../../../src/pages/prediction/za-powerball-prediction-for-tuesday-22-october-2024.js" /* webpackChunkName: "component---src-pages-prediction-za-powerball-prediction-for-tuesday-22-october-2024-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-results-daily-lotto-results-js": () => import("./../../../src/pages/results/daily-lotto-results.js" /* webpackChunkName: "component---src-pages-results-daily-lotto-results-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-results-lotto-plus-1-results-js": () => import("./../../../src/pages/results/lotto-plus-1-results.js" /* webpackChunkName: "component---src-pages-results-lotto-plus-1-results-js" */),
  "component---src-pages-results-lotto-plus-2-results-js": () => import("./../../../src/pages/results/lotto-plus-2-results.js" /* webpackChunkName: "component---src-pages-results-lotto-plus-2-results-js" */),
  "component---src-pages-results-lotto-results-js": () => import("./../../../src/pages/results/lotto-results.js" /* webpackChunkName: "component---src-pages-results-lotto-results-js" */),
  "component---src-pages-results-powerball-plus-results-js": () => import("./../../../src/pages/results/powerball-plus-results.js" /* webpackChunkName: "component---src-pages-results-powerball-plus-results-js" */),
  "component---src-pages-results-powerball-results-js": () => import("./../../../src/pages/results/powerball-results.js" /* webpackChunkName: "component---src-pages-results-powerball-results-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

